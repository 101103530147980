import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public ionicStorage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }
  async init() {
    const storage = await this.storage.create();
    this.ionicStorage = storage;
  }

  public set(key: string, value: string | boolean | number) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }

    return Preferences.set({ key, value });
  }

  public async get(key: string) {
    const oldValue = await this.ionicStorage?.get(key);
    if (oldValue && key == 'sb-db-auth-token') {
      this.set('sb-db-auth-token', oldValue);
      await this.ionicStorage?.remove(key);
      return oldValue;
    }
    const result = await Preferences.get({ key });
    return result.value;
  }

  public remove(key: string) {
    return Preferences.remove({ key });
  }

  public clear() {
    return Preferences.clear();
  }
}
