import { AfterViewInit, Component, OnDestroy, OnInit, viewChild } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonApp, IonLoading, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { TranslocoService } from '@jsverse/transloco';
import { HapticService } from '@services/haptic.service';
import { HelperService } from './services/helper.service';
import { SupabaseService } from './services/supabase.service';
import { StorageService } from '@services/storage.service';
import { StatusBar } from '@capacitor/status-bar';
import { SafeArea } from 'capacitor-plugin-safe-area';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  imports: [IonLoading, IonApp, IonRouterOutlet],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  getPlatform = Capacitor.getPlatform();

  readonly routerOutlet = viewChild.required(IonRouterOutlet);

  constructor(
    public helperService: HelperService,
    private supabaseService: SupabaseService,
    private platform: Platform,
    private translocoService: TranslocoService,
    private hapticService: HapticService,
    private storageService: StorageService
  ) {}

  async ngAfterViewInit() {
    this.supabaseService.cacheUser();
  }

  async loadHapticsAndLanguage() {
    const storedLang = await this.storageService.get('language');

    if (storedLang) {
      this.translocoService.setActiveLang(storedLang);
    }

    const storedHaptics = await this.storageService.get('enableHaptics');

    if (storedHaptics) {
      this.hapticService.enableHaptics.set(Boolean(storedHaptics));
    }
  }

  async ngOnInit() {
    await this.loadHapticsAndLanguage();

    this.translocoService.selectTranslation('de').subscribe();
    this.translocoService.selectTranslation('en').subscribe();

    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.addListener('keyboardWillShow', info => {
        this.alertPadding(info.keyboardHeight / 2);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.alertPadding(0);
      });
    }

    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setOverlaysWebView({
        overlay: false,
      });
      this.platform.backButton.subscribeWithPriority(-1, () => {
        if (!this.routerOutlet().canGoBack()) {
          App.exitApp();
        }
      });

      SafeArea.getSafeAreaInsets().then(data => {
        const { insets } = data;
        document.body.style.setProperty('--ion-safe-area-top', `${insets.top}px`);
        document.body.style.setProperty('--ion-safe-area-right', `${insets.right}px`);
        document.body.style.setProperty('--ion-safe-area-bottom', `${insets.bottom}px`);
        document.body.style.setProperty('--ion-safe-area-left', `${insets.left}px`);
      });
    }
  }

  async ngOnDestroy() {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.removeAllListeners();
    }
  }

  alertPadding(padding: number) {
    const ionAlert = document.querySelector('ion-alert');
    if (ionAlert) {
      ionAlert.style.transform = 'translateY(-' + padding.toString() + 'px)';
    }
  }
}
