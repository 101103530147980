import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import {
  AuthChangeEvent,
  createClient,
  Session,
  SupabaseClient,
  User,
  UserAttributes,
  VerifyOtpParams,
} from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient;

  public cachedUser: User | null = null;

  constructor(private storageService: StorageService) {
    const customStorage = {
      getItem: (key: string) => this.storageService.get(key) as Promise<string>,
      setItem: (key: string, value: string) => this.storageService.set(key, value),
      removeItem: (key: string) => this.storageService.remove(key),
    };

    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey, {
      auth: {
        storage: customStorage,
      },
    });
  }

  get user() {
    return this.supabase.auth.getUser().then(({ data }) => data?.user);
  }

  get session() {
    return this.supabase.auth.getSession().then(({ data }) => data?.session);
  }

  get profile() {
    return this.user
      .then(user => user?.id)
      .then(id => this.supabase.from('profiles').select(`username, website, avatar_url`).eq('id', id).single());
  }

  async cacheUser() {
    this.cachedUser = await this.user;
    if (this.cachedUser && this.cachedUser.email) {
      Sentry.setUser({ email: this.cachedUser.email });
      FirebaseAnalytics.setUserId({
        userId: this.cachedUser.email,
      }).catch(() => null);
    }
  }

  async verifyOtp(params: VerifyOtpParams) {
    return await this.supabase.auth.verifyOtp(params);
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  signIn(
    email: string,
    data?: {
      name?: string;
      orderNumber?: string;
    }
  ) {
    if (email == 'testuser@codext.de') {
      return this.supabase.auth.signInWithPassword({
        email,
        password: '12345678',
      });
    }
    return this.supabase.auth.signInWithOtp({ email, options: { data } });
  }

  signOut() {
    return this.supabase.auth.signOut();
  }

  async updateProfile(profile: User) {
    const user = await this.user;
    const update = {
      ...profile,
      id: user?.id,
      updated_at: new Date(),
    };

    return this.supabase.from('profiles').upsert(update);
  }

  updateUser(data: UserAttributes['data']) {
    return this.supabase.auth.updateUser({ data });
  }

  downloadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file);
  }
}
